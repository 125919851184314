import Pristine from 'pristinejs';
import { useFormStore } from '../store/form';

export const useValidator = (handle, formEl) => {
    const pristine = new Pristine(formEl);

    const reset = () => {
        useFormStore().setFormErrors(handle, []);

        // Clear out all errors for the form
        formEl.querySelectorAll('[dn-form-input__errors]').forEach((input) => {
            input.removeAttribute('dn-form-input__errors');
        });

        formEl.querySelectorAll('[aria-invalid]').forEach((input) => {
            input.removeAttribute('aria-invalid');
        });

        formEl.querySelectorAll('.dn-form-field__errors').forEach((input) => {
            input.remove();
        });
    };

    const validate = (pageClass) => {
        // Validate only the inputs on a given page
        const inputs = document.querySelectorAll(
            `.${pageClass} input:not([type^=hidden]):not([type^=submit]), select, textarea`
        );

        // Validate the inputs
        const valid = pristine.validate(inputs);

        // Show validation info - due to it being Vue, doesn't happen automatically
        showValidation(handle, pristine);

        return valid;
    };

    const applyServerValidation = (error) => {
        try {
            // Parse the GraphQL error
            const errors = JSON.parse(error.replace('[GraphQL]', ''));

            // Load in any errors from GQL into Pristine to show
            Object.entries(errors).forEach(([key, value]) => {
                const input = formEl.querySelector(`[name="${key}"]`);

                if (input) {
                    pristine.addError(input, value[0]);
                }
            });

            // Show errors, need to manually trigger this due to Vue incompatibility
            showValidation(handle, pristine);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    return {
        reset,
        validate,
        applyServerValidation
    };
};

const showValidation = (handle, pristine) => {
    const appliedErrors = [];

    // There's some errors here with Pristine when calling `getErrors()`
    // https://github.com/sha256/Pristine/pull/48
    pristine.fields.forEach((field) => {
        if (!field.errors) {
            field.errors = [];
        }
    });

    pristine.getErrors().forEach(({ input, errors }) => {
        input
            .closest('div.dn-input')
            ?.setAttribute('dn-form-input__errors', true);
        input
            .closest('div.dn-textarea')
            ?.setAttribute('dn-form-input__errors', true);

        // Also add attribute to the date picker fields with class dp__input
        if (input.classList.contains('dp__input')) {
            input.setAttribute('dn-form-input__errors', true);
        }

        const inputPageIndex = input
            .closest('[data-page-index]')
            .getAttribute('data-page-index');
        const inputLabel = input.getAttribute('data-label');
        const inputId = input.getAttribute('id');
        const inputType = input.getAttribute('type')
            ? input.getAttribute('type').toLowerCase()
            : '';

        // Add errors to the input fields
        input.setAttribute('aria-invalid', true);
        input.setAttribute('aria-errormessage', inputId);

        // Prevent errors applying multiple times
        if (appliedErrors.some(error => error.id === inputId)) {
            return;
        }

        // Add the error message below the fields
        const errorTextElement = document.createElement('div');
        errorTextElement.classList.add('dn-form-field__errors');
        errorTextElement.setAttribute('id', inputId);
        errorTextElement.innerHTML = errors.join('<br/>');

        let errorTextParent = input.closest('div.dn-form-field__wrapper');

        // Handle grouped fields like radio and checkboxes
        if (inputType === 'radio' || inputType === 'checkbox') {
            errorTextParent = errorTextParent.parentElement;
        }

        errorTextParent.appendChild(errorTextElement);

        // Save so we never have multiple errors per input
        appliedErrors.push({
            id: inputId,
            label: inputLabel,
            pageIndex: parseInt(inputPageIndex, 10),
            errors,
        });
    });

    useFormStore().setFormErrors(handle, appliedErrors);
};

// Default error messages per language
const messages = {
    en: {
        email: 'Please enter a valid email address',
        required: 'This field is required',
    },
    nl: {
        email: 'Vul een geldig e-mailadres in',
        required: 'Dit veld is verplicht',
    },
};

export const validationMessages = (field, requiredErrorMessage = '') => {
    // Check whether a custom error message is set from any of the subfields.
    requiredErrorMessage = requiredErrorMessage || field.errorMessage;
    const attrs = {};

    // Get the locale messages based on the current locale
    const localeMessages = messages[useFormStore().locale];

    // Set the error messages for the field
    if (field.required) {
        attrs['data-pristine-required-message'] =
            requiredErrorMessage || localeMessages.required;
    }

    if (field.handle === 'email') {
        attrs['data-pristine-email-message'] = localeMessages.email;
    }

    return attrs;
};
