<template>
    <BaseSection
        v-if="title || !!$slots.default"
        section-width="bleed"
        class="cta-section"
        :title="title"
        :description="richText"
        background-style="darkblue"
        :data-has-body="images && images.length > 0"
    >
        <template #callToAction>
            <CraftLink
                v-if="callToAction && callToAction.url"
                :link="callToAction"
                appearance="button"
                icon="arrow-right"
                :measure-gtm-cta="true"
            >
                {{ callToAction.text }}
            </CraftLink>

            <CraftLink
                v-if="secondaryCallToAction && secondaryCallToAction.url"
                :link="secondaryCallToAction"
                class="cta-section__secondary-cta base-button--secondary"
                appearance="button"
                icon="arrow-right"
                :measure-gtm-cta="true"
            >
                {{ secondaryCallToAction.text }}
            </CraftLink>
        </template>

        <BaseColumns
            v-if="images && images.length > 0"
            class="cta-section__images"
        >
            <template
                v-for="(image, index) in images"
                :key="`image-${index}`"
                v-slot:[`column-${index}`]
            >
                <BaseImage
                    class="cta-section__image"
                    :image="image"
                    :alt="image.alt"
                    :width="image.width"
                    :height="image.height"
                    :sizes="imageSizes"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null,
    },
    richText: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    },
    secondaryCallToAction: {
        type: Object,
        default: null
    },
    images: {
        type: Array,
        default: null
    },
});

const imageSizes = computed(() => {
    return '(min-width: 1440px) 768px, (min-width: 768px) 768px, 520px';
});
</script>

<style lang="less" src="./CtaSection.less"></style>
