import { defineStore } from 'pinia';

export const useDossierStore = defineStore('dossier-store', {
    state: () => {
        return {
            dossierList: [],
            trainingDossierFilterList: []
        };
    },

    getters: {
        dossiers() {
            return this.dossierList;
        },

        dossierIds() {
            if (!this.dossierList || this.dossierList.length === 0) {
                return [];
            }

            return this.dossierList.map(dossier => dossier.id);
        },

        dossierById() {
            return (dossierId) => {
                return this.dossiers.find(dossier => parseInt(dossier.id, 10) === parseInt(dossierId, 10));
            };
        },

        themes() {
            if (!this.dossiers || this.dossiers.length === 0) {
                return [];
            }

            const themes = [];

            this.dossiers.forEach(dossier => {
                if (!dossier.parent) {
                    return;
                }

                const themeExists = themes.find(theme => theme.id === dossier.parent.id);
                if (themeExists) {
                    return;
                }

                themes.push(dossier.parent);
            });

            return themes;
        }
    }
});
