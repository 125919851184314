<template>
    <main
        class="template"
        :data-has-sidebar="!!$slots.aside"
    >
        <div
            v-if="!!$slots.hero"
            ref="templateHeader"
            class="template__header"
        >
            <slot name="hero" />
        </div>

        <!-- @TODO: add padding if template__body is last page child element -->
        <div
            v-if="!!$slots.default"
            id="main-content-landmark"
            class="template__body"
        >
            <slot />

            <aside
                v-if="!!$slots.aside"
                class="template__aside"
            >
                <div class="template__aside__body">
                    <slot name="aside" />
                </div>
            </aside>
        </div>

        <div
            v-if="!!$slots.contentBlocks"
            data-before="false"
            class="template__body"
        >
            <slot name="contentBlocks" />
        </div>

        <div
            v-if="!!$slots.related"
            class="template__related"
        >
            <slot name="related" />
        </div>

        <div
            v-if="!!$slots.cta || !!footerCallToActionBlock"
            class="template__cta"
        >
            <slot name="cta">
                <CtaSection
                    :title="footerCallToActionBlock.sectionTitle"
                    :rich-text="footerCallToActionBlock.richText"
                    :call-to-action="footerCallToActionBlock.callToAction"
                    :secondary-call-to-action="footerCallToActionBlock.secondaryCallToAction"
                    :images="footerCallToActionBlock.images"
                />
            </slot>
        </div>
    </main>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { throttle } from 'lodash-es';

const authStore = useAuthStore();

const props = defineProps({
    entry: {
        type: Object,
        default: () => {},
        required: false
    }
});

const templateHeader = ref(null);
const isHeaderOverflowing = ref(false);

const { entry } = toRefs(props);

const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const footerCallToActionBlock = computed(() => {
    if (!entry) {
        return;
    }

    if (loggedIn.value) {
        return entry.value?.footerCtaLoggedIn?.[0];
    }

    return entry.value?.footerCtaLoggedOut?.[0];
});

const getHeaderBottom = () => {
    // Check if templateHeader is defined and window is available
    if (!templateHeader.value || typeof window === 'undefined' || window.innerHeight === 0) {
        return;
    }

    // Get the bottom position of templateHeader
    const { bottom } = templateHeader.value.getBoundingClientRect();

    // Check if the header is overflowing the viewport
    const isOverflowing = bottom > window.innerHeight;

    // Only update the DOM if the value has changed
    if (isOverflowing !== isHeaderOverflowing.value) {
        isHeaderOverflowing.value = isOverflowing;
        document.body.dataset.heroOverflowing = isOverflowing;
    }
};

const throttledResizeHandler = throttle(getHeaderBottom, 500);

onMounted(() => {
    if (!templateHeader.value) {
        return;
    }

    getHeaderBottom();
    window.addEventListener('resize', throttledResizeHandler);
});

onUnmounted(() => {
    window.removeEventListener('resize', throttledResizeHandler);
});
</script>

<style lang="less">
:root {
    --diagonal-height: 25px;

    @media @q-md-min {
        --diagonal-height: 50px;
    }

    @media @q-lg-min {
        --diagonal-height: 100px;
    }
}

.template__header {
    position: sticky;
    top: 0;
}

.template__body,
.template__cta {
    position: relative;
    z-index: 11; // Higher than regular navigation bar (10) but lower than footer (12)
}

.template__related {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 4.5rem;
    background: var(--color-lighterblue);
    z-index: 13; // Higher than regular navigation bar (10) and footer (12)
}

.template__body {
    background: var(--color-lightest);
    padding-top: 2rem;
    padding-bottom: 2rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: var(--diagonal-height);
        background: var(--color-lightest);
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
        transform: translateY(-100% + 1px);
    }

    &:last-child {
        padding-bottom: calc(var(--diagonal-height) + 6.25rem);
    }
}

.template__body[data-before='false'] {
    &:before {
        content: unset;
    }
}

.template[data-has-access='false'] .template__body {
    padding-top: 0;

    &:before {
        content: none;
    }
}

.template__aside {
    // By default the sidebar is hidden. It's only visible when
    // the content of the page is aligned to the left (see below)
    display: none;

    .page-container(true, var(--grid-maxWidth-content));
    width: 100%;
    padding-bottom: .5rem;
    padding-top: 1rem;
    margin-left: 0;


    @media screen and ( min-width: @sidebar-breakpoint ) {
        position: absolute;
        top: 4rem;
        right: 0;
        z-index: 2;

        padding: 0;
        width: calc(100% - var(--grid-maxWidth-content) - (var(--grid-side-gap-md) * 2) - 3.5rem);
        height: 100%;
        max-width: 25.625rem;
    }

    @media screen and ( min-width: 85rem ) {
        right: calc((100% - var(--grid-maxWidth-page)) / 2);
    }
}

.template__aside__body {
    max-width: 25.625rem;
}

body[data-hero-overflowing='true'] .template__header {
    position: relative;
}

/*
 * Alignment
 */
.template[data-has-sidebar='true'] {
    .template__aside {
        display: block;
    }

    .section[data-width='content'] {
        .section__body,
        .section__header {
            margin-left: 0;
        }
    }

    @media screen and ( min-width: @sidebar-breakpoint ) {
        // Make sure the first section is tall enough to accomodate
        // for the sidebar
        .template__body > .section:first-child .section__body {
            min-height: 12.5rem; // 200px
        }

        .template__aside__body {
            position: sticky;
            top: 5rem;
            margin-bottom: 6rem;
        }
    }
}

/*
 * Background variations
 */
body[data-background-style='lightblue'] {
    background: var(--color-lighterblue);

    .template__body {
        background: var(--color-lighterblue);

        &:before {
            background: var(--color-lighterblue);
        }
    }
}
</style>
