import { useAuthStore } from '~/store/auth.ts';
import { useDossierStore } from '~/store/dossiers';
import { useGtm } from '@gtm-support/vue-gtm';

class User {
    constructor(userDataResponse) {
        this.id = userDataResponse.id;
        this.firstName = userDataResponse.firstName;
        this.preposition = userDataResponse.preposition;
        this.lastName = userDataResponse.lastName;
        this.email = userDataResponse.email;
        this.function = userDataResponse.function;
        this.functions = userDataResponse.functions;
        this.birthDate = userDataResponse.birthDate;
        this.followingDossierIds = ref(userDataResponse.following || []);
        this.contentPermissions = userDataResponse.contentPermissions;
        this.dossierNotificationFrequency = ref(userDataResponse.dossierNotificationFrequency);

        // Computed data
        this.fullName = this.getFullName();
        this.companyName = this.getCompanyName();
        this.isProfileManager = this.isProfileManager();
    }

    getFullName() {
        let fullName = this.firstName;

        if (this.preposition) {
            fullName += ` ${this.preposition}`;
        }

        if (this.lastName) {
            fullName += ` ${this.lastName}`;
        }

        return fullName;
    }

    getCompanyName() {
        const primaryFunction = this.getPrimaryFunction();

        if (!primaryFunction) {
            return;
        }

        return primaryFunction.functionName;
    }

    getPrimaryFunction() {
        if (!this.functions || !this.functions.length) {
            return;
        }

        return this.functions.find(userFn => userFn.isMainFunction === true);
    }

    isProfileManager() {
        if (!this.functions || !this.functions.length) {
            return false;
        }

        return !!this.functions.find(userFn => userFn.isProfileManager === true);
    }

    canManageOrganization(organizationId) {
        if (!this.functions || !this.functions.length) {
            return;
        }

        const targetfunction = this.functions.find((userFn) => {
            return userFn.organizationId === organizationId;
        });

        if (!targetfunction) {
            return false;
        }

        return targetfunction.isProfileManager === true;
    }

    isFollowingDossier(dossierId) {
        return toValue(this.followingDossierIds).includes(parseInt(dossierId, 10));
    }

    addDossierIds(ids) {
        ids.forEach(id => {
            if (toValue(this.followingDossierIds).includes(id)) {
                return;
            }

            this.followingDossierIds.value.push(parseInt(id, 10));

            try {
                useGtm()?.trackEvent({
                    event: 'dossier_follow',
                    dossier: useDossierStore()?.dossierById(id)?.title,
                    dossierId: id
                });
            } catch {
                // do nothing
            }
        });
    }

    removeDossierIds(ids) {
        this.followingDossierIds.value.forEach((dossierId, index) => {
            if (!ids.includes(dossierId)) {
                return;
            }

            this.followingDossierIds.value.splice(index, 1);

            const dossier = useDossierStore()?.dossierById(dossierId);

            try {
                useGtm()?.trackEvent({
                    event: 'dossier_unfollow',
                    dossier: dossier?.title,
                    dossierId: dossierId
                });
            } catch {
                // do nothing
            }
        });
    }

    async followDossiers(payload) {
        const authStore = useAuthStore();

        const promises = [];
        payload.forEach(dossierId => {
            promises.push(new Promise(async(resolve, reject) => { /* eslint-disable-line no-async-promise-executor */
                const data = await useLaravelApi(
                    `/user/${authStore.windexId}/follows-topic/${dossierId}`,
                    {
                        accessToken: authStore.accessToken,
                        method: 'POST'
                    }
                );

                if (data.error) {
                    reject(data.error);
                }

                // IDs from the API can be strings or integers
                resolve(parseInt(data?.topic_id, 10));
            }));
        });

        await Promise.all(promises).then(async(followedIds) => {
            this.addDossierIds(followedIds);

            const user = authStore.user;

            followedIds.forEach((id) => {
                if (user.following && !user.following.includes(id)) {
                    user.following.push(id);
                }
            });

            await authStore.getUserFromApi();
        }).catch(error => {
            console.error(error);
        });
    }

    async unfollowDossiers(payload) {
        const authStore = useAuthStore();

        const promises = [];

        payload.forEach(dossierId => {
            promises.push(new Promise(async(resolve, reject) => { /* eslint-disable-line no-async-promise-executor */
                const data = await useLaravelApi(
                    `/user/${authStore.windexId}/follows-topic/${dossierId}`,
                    {
                        accessToken: authStore.accessToken,
                        method: 'DELETE'
                    }
                );

                if (data.error) {
                    reject(data.error);
                }

                // IDs from the API can be strings or integers
                resolve(parseInt(data, 10));
            }));
        });

        await Promise.all(promises).then(async(unfollowedIds) => {
            this.removeDossierIds(unfollowedIds);

            const user = authStore.user;

            unfollowedIds.forEach((id) => {
                if (user.following && user.following.indexOf(id) > 0) {
                    user.following.splice(user.following.indexOf(id), 1);
                }
            });

            await authStore.getUserFromApi();
        }).catch(error => {
            console.error(error);
        });
    }

    async setDossierUpdateFrequency(frequency) {
        const authStore = useAuthStore();

        await useLaravelApi(`/user/${authStore.windexId}/preferences/dossier-updates/${frequency}`, {
            method: 'POST',
            accessToken: authStore.accessToken
        });

        authStore.user.dossierNotificationFrequency = frequency; // Also update the original user property, which will be stored in the cache

        await authStore.getUserFromApi();
    }
}

export default User;
