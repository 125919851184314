<template>
    <DnFormField
        class="base-form-field"
    >
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope"
            />
        </template>
    </DnFormField>
</template>

<script setup>
import DnFormField from '@digitalnatives/form-field';
</script>

<style src="./BaseFormField.less" lang="less" />
