import revive_payload_client_ogEBp9sTnG from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eYlxo5JPdX from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ATXJxTVJa3 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_sKvWwhYhEk from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_less@4.2.0_6apj4privfybcppyojmf55yicm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qFjs6p2tVA from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bGzA0bUEWN from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_F41THxFCoK from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bdmD8EufTN from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PONYjBVMuZ from "/builds/tln/tln-app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/tln/tln-app/.nuxt/components.plugin.mjs";
import prefetch_client_h2Mdn26Xs8 from "/builds/tln/tln-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_tc5qkid5c6tx2l5k7uzoezjvq4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/tln/tln-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_vxb5z6tSLN from "/builds/tln/tln-app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.22.4_vite@5.4._nze2tyvnedduoflaydmovhz5jy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_QY24bXSDK8 from "/builds/tln/tln-app/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_xuacadn6q2xpgpkiki6r42bvdy/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_FGYvZX1AKD from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-bugsnag@7.4.0-beta.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.22.4_vue-router@4.4.5__aczgt6cqazdlqmolmireo6puiq/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import session_client_AiaCeNj47c from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-auth-utils@0.3.9_magicast@0.3.5_rollup@4.22.4/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import nuxt_plugin_3jXOSgNXaX from "/builds/tln/tln-app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.22.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import axios_QMFgzss1s4 from "/builds/tln/tln-app/plugins/axios.ts";
import fetchUser_aFyolf91xE from "/builds/tln/tln-app/plugins/fetchUser.ts";
import formie_yNFepVHNmd from "/builds/tln/tln-app/plugins/formie.js";
import gtm_cXsUZobmSF from "/builds/tln/tln-app/plugins/gtm.ts";
export default [
  revive_payload_client_ogEBp9sTnG,
  unhead_eYlxo5JPdX,
  router_ATXJxTVJa3,
  _0_siteConfig_sKvWwhYhEk,
  payload_client_qFjs6p2tVA,
  navigation_repaint_client_bGzA0bUEWN,
  check_outdated_build_client_F41THxFCoK,
  chunk_reload_client_bdmD8EufTN,
  plugin_vue3_PONYjBVMuZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h2Mdn26Xs8,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_vxb5z6tSLN,
  plugin_QY24bXSDK8,
  plugin_FGYvZX1AKD,
  session_client_AiaCeNj47c,
  nuxt_plugin_3jXOSgNXaX,
  axios_QMFgzss1s4,
  fetchUser_aFyolf91xE,
  formie_yNFepVHNmd,
  gtm_cXsUZobmSF
]