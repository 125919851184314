<template>
    <div
        class="error"
        name="default"
    >
        <TemplateBase>
            <template #hero>
                <BaseHero
                    :title="title"
                    :description="`<p>${description}</p>`"
                />
            </template>

            <template #default>
                <SearchKeywords v-show="error.statusCode !== 422" />
            </template>
        </TemplateBase>
    </div>
</template>

<script setup>

const props = defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const title = props.error && props.error.statusCode === 404 ? 'Pagina niet gevonden' : 'Er is een fout opgetreden';

const description = computed(() => {
    if (props.error.statusCode === 404) {
        return 'Deze pagina bestaat niet (meer). Misschien helpen onderstaande links je weer op weg';
    }

    return 'Er is een fout opgetreden. Ga terug naar de <a href="/">homepage</a>.';
});
</script>