<template>
    <slot name="FormieRadio">
        <BaseSelect
            v-bind="$attrs"
            :deselect-sr-label="deselectSrLabel"
            :selected-sr-label="selectedSrLabel"
            :toggle-sr-label="toggleSrLabel"
        >
            <slot />
        </BaseSelect>
    </slot>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
    deselectSrLabel: {
        type: String,
        default: 'Deselecteer optie',
    },
    selectedSrLabel: {
        type: String,
        default: 'Geselecteerde optie',
    },
    toggleSrLabel: {
        type: String,
        default: 'Selecteer een optie',
    },
});

// Props to refs
const { deselectSrLabel, selectedSrLabel, toggleSrLabel } = toRefs(props);
</script>

<style lang="less">
.dn-select {
    .dn-select__list-selected-options {
        gap: .5rem;

        button {
            .typography-label-small();
            --color-border: var(--color-theme-text);
            --color-border-hover: var(--color-theme-text);
            display: inline-flex;
            align-items: flex-start;
            // Subtract border size from padding, i.e  8, 12, 6
            padding: .5rem .75rem .375rem;
            background: var(--color-theme-background-inverse, var(--color-primary));
            border: 2px solid var(--color-border, var(--color-lightest));
            border-radius: var(--borderRadius-xxl);
            color: var(--color-theme-text-inverse, var(--color-lightest));
        }
    }

    .dn-select__list-selected-option {
        padding: 0;
    }
}
</style>
