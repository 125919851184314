<template>
    <slot
        :height="height"
        :width="width"
        :src="src"
        :avif-src="avifSrc"
        :webp-src="webpSrc"
        :srcset="srcset"
        :avif-srcset="avifSrcset"
        :webp-srcset="webpSrcset"
    />
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            required: true
        },
    },
    computed: {
        height() {
            return this.image?.height;
        },
        width() {
            return this.image?.width;
        },
        src() {
            return this.image?.url;
        },
        avifSrc() {
            return this.image?.avif;
        },
        webpSrc() {
            return this.image?.webp;
        },
        srcses() {
            return Object.entries(this.image)
                .map(([key, value]) => {
                    const matches = key.match(/^w([\d]+)$/);

                    if (value && matches?.[1]) {
                        return `${value} ${matches[1]}w`;
                    }

                    return false;
                })
                .filter(v => v);
        },
        webpSrcses() {
            return Object.entries(this.image)
                .map(([key, value]) => {
                    const matches = key.match(/^w([\d]+)_webp$/);

                    if (value && matches?.[1]) {
                        return `${value} ${matches[1]}w`;
                    }

                    return false;
                })
                .filter(v => v);
        },
        avifSrcses() {
            return Object.entries(this.image)
                .map(([key, value]) => {
                    const matches = key.match(/^w([\d]+)_avif$/);

                    if (value && matches?.[1]) {
                        return `${value} ${matches[1]}w`;
                    }

                    return false;
                })
                .filter(v => v);
        },
        srcset() {
            return this.srcses?.join(',');
        },
        webpSrcset() {
            return this.webpSrcses?.join(',');
        },
        avifSrcset() {
            return this.avifSrcses?.join(',');
        },
    }
};
</script>
