<template>
    <DelayHydration>
        <header
            class="hero"
            data-hero
            :class="{
                'hero--has-image': !!singleImage || !!$slots.image
            }"
            :data-background-style="backgroundStyle"
            :data-layout="layout"
            :data-logged-in="loggedIn"
            v-bind="$attrs"
        >
            <div class="hero__container">
                <div
                    v-if="!!$slots.preTitle"
                    class="hero__pre-title"
                >
                    <slot name="preTitle" />
                </div>

                <hgroup
                    v-if="!!props.title || !!$slots.title || !!$slots.titleElement"
                    class="hero__title"
                >
                    <slot name="titleElement">
                        <h1 v-if="props.title || !!$slots.title">
                            <slot name="title">{{ props.title }}</slot>
                        </h1>
                    </slot>
                </hgroup>

                <div class="hero__body">
                    <div
                        v-if="!!props.description || !!$slots.description"
                        class="hero__description"
                    >
                        <slot name="description">
                            <!-- eslint-disable -->
                            <div
                                v-if="props.description"
                                v-html="props.description"
                            />
                            <!-- eslint-enable -->
                        </slot>
                    </div>

                    <slot />
                </div>

                <div
                    v-if="!multipleImages && !!singleImage || !!$slots.image"
                    class="hero__image"
                >
                    <slot name="image">
                        <BaseImage
                            :image="singleImage"
                            loading="eager"
                            fetchpriority="high"
                        />
                    </slot>
                </div>
            </div>

            <div
                v-if="!!$slots.slider"
                class="hero__slider"
            >
                <slot name="slider" />
            </div>

            <div
                v-if="!!$slots.navigation"
                class="hero__navigation"
            >
                <slot name="navigation" />
            </div>
            <div
                v-if="!!multipleImages"
                class="hero__images"
            >
                <BaseImage
                    v-for="image in props.images"
                    :key="`hero-image-${image.id}}`"
                    :alt="image.alt"
                    :image="image"
                    :sizes="imageSizes"
                    loading="eager"
                    fetchpriority="high"
                />
            </div>
        </header>
    </DelayHydration>
</template>

<script setup>
import { ref } from 'vue';
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { useAuthStore } from '~/store/auth';

const authStore = useAuthStore();

const props = defineProps({
    images: {
        type: [Object, Array],
        default: null
    },
    title: {
        type: String,
        default: null
    },
    description: {
        type: String,
        default: null
    },
    backgroundStyle: {
        type: String,
        default: 'dark',
        validator: value => ['dark', 'light'].includes(value)
    },
    layout: {
        type: String,
        default: 'wide/1-column',
        validator: value => ['wide/1-column', 'wide/2-columns', 'narrow'].includes(value)
    }
});

const multipleImages = ref(props.images?.length > 1 ?? false);
const singleImage = ref(props.images?.[0] ?? null);

useHead({
    bodyAttrs: {
        'data-hero-layout': props.layout,
        'data-hero-background-style': props.backgroundStyle,
        'data-hero-image-count': props.images?.length ?? 'none'
    }
});

const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const imageSizes = computed(() => {
    return '(min-width: 1440px) 768px, (min-width: 768px) 768px, 520px';
});
</script>

<style lang="less" src="./BaseHero.less" />
