import { COOKIE_KEY_AUTH_SESSION, COOKIE_KEY_INITIAL_PAGE_PATH } from '~/constants/cookies';
import { useAuthStore } from '~/store/auth';

export default defineNuxtRouteMiddleware((to) => {
    if (import.meta.server) {
        return;
    }

    const { loggedIn } = useUserSession();

    const initalPageCookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH);
    const sessionCookie = useCookie(COOKIE_KEY_AUTH_SESSION);

    if (initalPageCookie.value) {
        const targetPath = initalPageCookie.value;

        initalPageCookie.value = null;

        refreshCookie(COOKIE_KEY_INITIAL_PAGE_PATH);

        return navigateTo(decodeURIComponent(targetPath));
    }

    if (sessionCookie.value && !loggedIn.value) {
        useAuthStore().setInitialPageCookie(to.path);

        return navigateTo('/auth/login', {
            external: true
        });
    }
});
