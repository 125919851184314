<template>
    <NuxtLayout
        :name="isHlamlError ? 'hlaml' : 'default'"
    >
        <HlamlErrorPage
            v-if="isHlamlError"
            :error="error"
        />

        <ErrorPage
            v-else
            :error="error"
        />
    </NuxtLayout>
</template>

<script setup>
import ErrorPage from '~/components/ErrorPage/ErrorPage.vue';
import HlamlErrorPage from '~/components/Hlaml/ErrorPage/HlamlErrorPage.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

const isHlamlError = route.meta.layout === 'hlaml';
</script>
