<template>
    <div
        :class="{
            'dn-radio--disabled': disabled
        }"
        class="dn-radio"
    >
        <label>
            <input
                v-model="inputValue"
                type="radio"
                v-bind="inputAttrs"
            >

            <span class="dn-radio__custom">
                <slot
                    name="custom"
                    :is-checked="isSelected"
                />
            </span>

            <span
                v-if="!!$slots.default"
                class="dn-radio__label"
            >
                <slot :is-checked="isSelected" />
            </span>
        </label>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    // Vue model
    modelValue: {
        type: [String, Boolean, Object, Number, Array],
        default: undefined
    },

    // HTML ID, used for binding label to input
    id: {
        type: String,
        default: undefined
    },

    // HTML input name
    name: {
        type: [String, Number],
        default: undefined
    },

    // Radio value to be used when checked
    value: {
        type: [String, Boolean, Object, Number],
        default: undefined
    },

    // Whether it is required to check the field
    required: {
        type: Boolean,
        default: false
    },

    // Disable the radio
    disabled: {
        type: Boolean,
        default: false
    },

    // Allow for custom attributes to be bound to the input element
    inputBindings: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(['update:modelValue']);

const isSelected = computed(() => {
    return props.modelValue === props.value;
});

const handleInput = function() {
    if (props.disabled) {
        return;
    }

    emit('update:modelValue', props.value);
};

const inputValue = computed({
    get: () => {
        return props.modelValue;
    },
    set: () => {
        handleInput();
    },
});

const inputAttrs = computed(() => {
    return {
        id: props.id,
        name: props.name,
        disabled: props.disabled,
        required: props.required,
        value: props.value,
        ...props.inputBindings
    };
});
</script>

<style lang="less" src="./BaseRadio.less" />
