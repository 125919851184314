<template>
    <div
        v-if="searchKeywords && searchKeywords.length > 0"
        class="search-keywords"
        section-width="content"
    >
        <div class="search-keywords__title">Populaire zoekopdrachten</div>
        <div class="search-keywords__keywords">
            <BaseButton
                v-for="keyword in searchKeywords"
                :key="keyword.id"
                element="a"
                :href="`/zoeken?q=${encodeURIComponent(keyword.title)}`"
                class="base-button search-keywords__keyword base-button--secondary"
                size="large"
            >
                {{ keyword.title }}
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import SearchKeywordsQuery from '~/graphql/queries/global/SearchKeywords.graphql';

const { data } = await useAsyncQuery({
    query: SearchKeywordsQuery
});

const searchKeywords = computed(() => data?.value?.globalSets[0]?.keywords ?? []);
</script>

<style lang="less" src="./SearchKeywords.less"></style>
