<template>
    <DnIcon
        class="base-icon"
    >
        <template
            v-for="name of Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope"
            />
        </template>
    </DnIcon>
</template>

<script setup>
import DnIcon from '@digitalnatives/icon';
</script>
