import { useSubField } from './subField';
import { validationMessages } from './validations';

export const useField = (field = {}) => {
    const getInputBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        return {
            id: getId(fieldVar.handle),
            name: getName(fieldVar.handle),
            required: fieldVar.required,
            placeholder: fieldVar.placeholder,
            'data-label': fieldVar.label,
            ...validationMessages(fieldVar, fieldVar.requiredErrorMessage),
            ...attributesTable(fieldVar.inputAttributes),
        };
    };

    const getFieldBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        return {
            class: {
                [fieldVar.cssClasses]: fieldVar.cssClasses,
                'formie-field--disabled': fieldVar.visibility === 'disabled',
                'formie-field--hidden': fieldVar.visibility === 'hidden',
            },
            ...attributesTable(fieldVar.containerAttributes),
        };
    };

    const getLabelBindings = (fieldVar = field) => {
        if (!fieldVar) {
            return {};
        }

        return {
            for: getId(fieldVar.handle),
            'data-label-position': getLabelPosition(fieldVar.labelPosition),
        };
    };

    const getDefaultValue = () => {
        if (field.typeName === 'Field_Name' && field.useMultipleFields) {
            const { getNameSubFields, initializeSubFieldsData } = useSubField();
            return initializeSubFieldsData(getNameSubFields(field));
        }

        if (field.typeName === 'Field_Address') {
            const { getAddressSubFields, initializeSubFieldsData } =
                useSubField();
            return initializeSubFieldsData(getAddressSubFields(field));
        }

        if (field.typeName === 'Field_Number') {
            return Number(field.defaultValue) || 0;
        }

        if (field.typeName === 'Field_Checkboxes') {
            return defaultOptions(field.options);
        }

        if (field.typeName === 'Field_Dropdown') {
            return defaultOption(field.options).value;
        }

        if (field.typeName === 'Field_Radio') {
            return defaultOption(field.options).value;
        }

        if (field.typeName === 'Field_Date') {
            if (!field.defaultDate) {
                return null;
            }

            if (field.includeTime && !field.includeDate) {
                const date = new Date(field.defaultDate);
                const hours = date.getHours();
                const minutes = date.getMinutes();

                // prefix with 0 if needed
                return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
            }

            return field.defaultDate;
        }

        return field.defaultValue || '';
    };

    const defaultOption = (options = [], defaultValue = null) => {
        if (!options || options.length === 0) {
            return null;
        }

        if (defaultValue) {
            return options.find(option => option.value === defaultValue);
        }

        return options.find(option => option.isDefault) || options[0];
    };

    const defaultOptions = (options = []) => {
        if (!options || options.length === 0) {
            return [];
        }

        const optionsList = options.filter(option => option.isDefault);
        if (optionsList.length > 0) {
            return optionsList.map(option => option.value);
        }

        return [];
    };

    return {
        getInputBindings,
        getFieldBindings,
        getLabelBindings,
        getDefaultValue,
        defaultOption,
    };
};

const getId = (handles) => {
    if (handles && (typeof handles === 'object' || Array.isArray(handles))) {
        return handles.join('-');
    }

    return handles;
};

const getName = (handles) => {
    if (handles && (typeof handles === 'object' || Array.isArray(handles))) {
        return `${handles.join('.')}`;
    }

    return handles;
};

// Position can be something like: verbb\\formie\\positions\\Hidden
const getLabelPosition = (position) => {
    if (!position) {
        return '';
    }

    const parts = position.split('\\');
    return parts[parts.length - 1].toLowerCase();
};

export const attributesTable = (table) => {
    const attrs = {};

    if (table && Array.isArray(table)) {
        table.forEach((row) => {
            attrs[row.label] = row.value;
        });
    }

    return attrs;
};
