<template>
    <DnTextarea
        :wrapper-attrs="{
            class: 'base-textarea'
        }"
    >
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope"
            />
        </template>
    </DnTextarea>
</template>

<script setup>
import { DnTextarea } from '@digitalnatives/form-input';
</script>

<style src="./BaseTextarea.less" lang="less"></style>
