<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-if="content"
        ref="baseRichText"
        class="base-rich-text"
        v-html="content"
    />
    <!-- eslint-enable -->
    <div
        v-else
        class="base-rich-text"
    >
        <slot />
    </div>
</template>

<script setup>
defineProps({
    content: {
        type: String,
        default: ''
    }
});

const baseRichText = useTemplateRef('baseRichText');

onMounted(() => {
    if (baseRichText.value) {
        const links = baseRichText.value.querySelectorAll('a[target="_blank"]');
        links.forEach(link => {
            link.removeAttribute('target');
        });
    }
});
</script>

<style lang="less" src="./BaseRichText.less"></style>
