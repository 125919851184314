import { useAuthStore } from '~/store/auth';

export default defineNuxtPlugin(async() => {
    const authStore = useAuthStore();

    const { loggedIn } = useUserSession();

    if (loggedIn.value && authStore.user === null) {
        await authStore.fetchUser();
    }
});
