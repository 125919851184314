import { defineStore } from 'pinia';
import UserModel from '~/utils/UserModel';
import type { Session, User as UserType } from '~/@types/auth';
import {
    COOKIE_KEY_INITIAL_PAGE_PATH,
    COOKIE_KEY_AUTH_ROLE
} from '~/constants/cookies';
import { ofetch } from 'ofetch';

export const GRAPH_MS_ENDPOINT = 'https://graph.microsoft.com/v1.0/me?$select=country,mail,surname,givenName,id,jobTitle';

const emptyUser: UserType = {
    id: null,
    windexId: null,
    firstName: '',
    preposition: '',
    lastName: '',
    email: '',
    function: '',
    functions: [],
    birthDate: null,
    following: [],
    contentPermissions: {
        FENEX: 0 as 0,
        TLN: 0 as 0,
        TLN_INTERNATIONAL: 0 as 0
    },
    dossierNotificationFrequency: 'none' as 'none'
};

export const useAuthStore = defineStore('auth-store', {
    state: () => ({
        user: null as UserType | null,
    }),

    getters: {
        session(): Session {
            return useUserSession().session.value;
        },

        windexId(): string|null {
            return this.session.user.windexId ? this.session.user.windexId : null;
        },

        accessToken(): String|null {
            return this.session?.accessToken || null;
        },

        userModel(): object|null {
            if (!this.user) {
                return null;
            }

            return new UserModel(this.user);
        },

        initialPage(): string|undefined {
            const cookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH, {
                sameSite: 'strict'
            });

            return cookie.value || undefined;
        },

        loggedIn(): boolean {
            return useUserSession().loggedIn.value && !!this.user;
        }
    },

    actions: {
        async fetchUser() {
            if (this.session === null || Object.keys(this.session).length === 0) {
                return;
            }

            const path = `/user/${this.session.user.windexId}`;

            let response = await useLaravelApi(
                path,
                undefined,
                false
            );

            if (response && Object.keys(response).length !== 0) {
                this.user = response;
            } else {
                response = await ofetch(GRAPH_MS_ENDPOINT, {
                    headers: {
                        Authorization: `Bearer ${this.session.accessToken}`
                    }
                });

                this.user = {
                    ...emptyUser,
                    id: response.country,
                    windexId: response.country,
                    firstName: response.givenName || '',
                    lastName: response.surname || '',
                    email: response.mail || '',
                    function: response.jobTitle || ''
                };
            }
        },

        async getUserFromApi() {
            if (this.session === null || Object.keys(this.session).length === 0) {
                return;
            }

            await this.fetchUser();

            if (this.user) {
                this.setUserRoleGTM(this.user);
            }
        },

        async deleteUserSession() {
            const { baseURL } = useRuntimeConfig().public;

            this.unsetUserRoleGTM();

            await useNuxtApp().runWithContext(async() => {
                await navigateTo(baseURL + '/auth/logout', {
                    external: true
                });
            });
        },

        setInitialPageCookie(path: string) {
            const cookie = useCookie(COOKIE_KEY_INITIAL_PAGE_PATH, {
                sameSite: 'strict'
            });

            cookie.value = path;

            refreshCookie(COOKIE_KEY_INITIAL_PAGE_PATH);
        },

        // Add user role to cookies for GTM
        setUserRoleGTM(user: UserType) {
            useNuxtApp().runWithContext(() => {
                const contentPermissions = user.contentPermissions;
                let userRole = null;
    
                if (contentPermissions?.TLN === 1) {
                    userRole = 'tln_lid';
                }
    
                // Overwrite TLN role if user is also international member
                if (contentPermissions?.TLN_INTERNATIONAL === 1) {
                    userRole = 'internationaal_lid';
                }
    
                const cookie = useCookie(COOKIE_KEY_AUTH_ROLE, {
                    sameSite: 'strict'
                });
    
                if (cookie.value !== userRole) {
                    cookie.value = userRole;
                }
            });
        },

        unsetUserRoleGTM() {
            const cookie = useCookie(COOKIE_KEY_AUTH_ROLE);

            cookie.value = null;
        }
    }
});
