<template>
    <DnInput
        :wrapper-attrs="{ class: 'base-input' }"
        :clearable="false"
    >
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope"
            />
        </template>
    </DnInput>
</template>

<script setup>
import DnInput from '@digitalnatives/form-input';
</script>

<style src="./BaseInput.less" lang="less"></style>
