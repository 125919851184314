<template>
    <DnButton
        class="dn-button base-button"
        :class="{
            'base-button--has-icon': !!$slots.icon,
            'base-button--icon-only': (!$slots.default && !!$slots.icon) || iconPosition === 'center'
        }"
        :data-icon-position="iconPosition"
        :data-size="size"
    >
        <slot />

        <span
            v-if="iconPosition === 'center' && srLabel"
            class="sr-only"
        >
            {{ srLabel }}
        </span>

        <div
            v-if="!!$slots.icon || icon"
            class="base-button__icon"
        >
            <slot name="icon">
                <BaseIcon :icon="icon" />
            </slot>
        </div>

        <div
            v-if="isLoading"
            class="base-button__loader"
        />
    </DnButton>
</template>

<script setup>
import DnButton from '@digitalnatives/button';
import BaseIcon from '~/components/BaseIcon/BaseIcon.vue';

// props which defines if the icon is placed before or after the text
defineProps({
    iconPosition: {
        type: String,
        default: 'after',
        validator: (value) => ['before', 'center', 'after'].includes(value)
    },
    icon: {
        type: String,
        default: null
    },
    size: {
        type: String,
        default: 'large',
        validator: (value) => ['small', 'medium', 'large'].includes(value)
    },
    srLabel: {
        type: String,
        default: null
    },
    isLoading: {
        type: Boolean,
        default: false
    },
});
</script>

<style src="./BaseButton.less" lang="less"></style>
