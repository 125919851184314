<template>
    <div
        :class="{
            'dn-checkbox--disabled': disabled
        }"
        class="dn-checkbox"
    >
        <label>
            <input
                v-model="inputValue"
                v-bind="inputAttrs"
                type="checkbox"
            >

            <span class="dn-checkbox__custom">
                <slot
                    name="custom"
                    :is-checked="isChecked"
                />
            </span>

            <span
                v-if="!!$slots.default"
                class="dn-checkbox__label"
            >
                <slot :is-checked="isChecked" />
            </span>
        </label>
    </div>
</template>

<script setup>
import { computed, toValue } from 'vue';

const props = defineProps({
    // Vue model
    modelValue: {
        type: [String, Boolean, Object, Number, Array],
        default: undefined
    },

    // HTML ID, used for binding label to input
    id: {
        type: String,
        default: undefined
    },

    // HTML input name
    name: {
        type: [String, Number],
        default: undefined
    },

    // Checkbox value to be used when checked
    value: {
        type: [String, Boolean, Object, Number],
        default: undefined
    },

    // Whether it is required to check the box
    required: {
        type: Boolean,
        default: false
    },

    // Disable the checkbox
    disabled: {
        type: Boolean,
        default: false
    },

    // Allow for custom attributes to be bound to the input element
    inputBindings: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(['update:modelValue']);

// Note: there is there types of checkboxes
// 1. Single value checkbox (a single value is passed)
// 2. Boolean checkbox (no value is passed)
// 3. Grouped checkbox (the model is an array)

const isGroupedCheckbox = function() {
    return Array.isArray(toValue(props.modelValue));
};

const isBooleanCheckbox = function() {
    return !props.value;
};

const isSingleValueCheckbox = function() {
    return !isGroupedCheckbox() && props.value;
};

const isChecked = computed(() => {
    if (isGroupedCheckbox()) {
        return toValue(props.modelValue).includes(props.value);
    }

    if (isBooleanCheckbox()) {
        return toValue(props.modelValue) === true;
    }

    if (isSingleValueCheckbox()) {
        return toValue(props.modelValue) === props.value;
    }

    return false;
});

// The inputValue stores the current value of the actual input element
// Note: this may differ from the value or modelValue (v-model) properties.
// This is especially true for single value checkboxes.
const inputValue = computed({
    get: () => {
        // Vue model only handles array checkboxes and boolean checkboxes by default
        // For single value checkboxes, we need to handle the input's model value ourselves
        // @TODO: refactor this to true-value / false-value native properties
        if (isSingleValueCheckbox()) {
            return toValue(props.modelValue) === props.value;
        }

        return props.modelValue;
    },
    set: () => {
        handleInput();
    },
});

const handleInput = function() {
    if (props.disabled) {
        return;
    }

    let newModelValue = null;

    // Handle grouped checkbox using (array) models
    if (isGroupedCheckbox()) {
        newModelValue = [...props.modelValue];

        if (!toValue(isChecked)) {
            newModelValue.push(props.value);
        } else {
            const index = newModelValue.indexOf(props.value);

            if (index !== -1) {
                newModelValue.splice(index, 1);
            }
        }
    }

    if (isBooleanCheckbox()) {
        newModelValue = !toValue(props.modelValue);
    }

    if (isSingleValueCheckbox()) {
        if (toValue(isChecked)) {
            newModelValue = null;
        } else {
            newModelValue = props.value;
        }
    }

    emit('update:modelValue', newModelValue);
};

const inputAttrs = computed(() => {
    return {
        id: props.id,
        name: props.name,
        disabled: props.disabled,
        required: props.required,
        value: props.value,
        ...props.inputBindings
    };
});
</script>

<style lang="less" src="./BaseCheckbox.less" />
