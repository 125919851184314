import axios from 'axios';

export default defineNuxtPlugin(() => {
    const instance = axios.create({ timeout: 10000 });

    return {
        provide: {
            axios: instance
        }
    };
});
