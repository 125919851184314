<template>
    <CraftLink
        v-if="!!link && (link.url || link.element)"
        :link="link"
        class="hlaml-highlighted-page"
    >
        <div class="hlaml-highlighted-page__info">
            <p class="hlaml-highlighted-page__title">
                {{ link.text }}
            </p>
            <p
                v-if="subTitle"
                class="hlaml-highlighted-page__sub-title"
            >
                {{ subTitle }}
            </p>
        </div>
        <div class="hlaml-highlighted-page__image">
            <BaseImage
                v-if="image"
                :image="image"
                loading="eager"
                fetchpriority="high"
            />
        </div>
    </CraftLink>
</template>

<script setup>
defineProps({
    link: {
        type: Object,
        default: null
    },
    subTitle: {
        type: String,
        default: null
    },
    image: {
        type: Object,
        default: null
    },
});
</script>

<style lang="less">
.dn-button.base-button.hlaml-highlighted-page {
    display: grid;
    grid-template-columns: 1fr 100px;
    background-color: var(--color-red-aaa);
    color: var(--color-lightest);
    padding-bottom: 0;
    border-radius: .25rem;

    .hlaml-highlighted-page__info {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        padding: 1rem;
    }

    .hlaml-highlighted-page__title {
        .typography-h4();
        font-style: italic;
        margin: 0;
    }

    .hlaml-highlighted-page__sub-title {
        .typography-small();
    }

    .hlaml-highlighted-page__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100%;
        overflow: hidden;

        .base-image {
            height: 100%;
            transform: scale(1.035);
            transition: all var(--transition-timing-fast) var(--transition-easing-in-out);
        }
    }

    &:hover {
        background-size: 0;

        .hlaml-highlighted-page__image .base-image {
            transform: scale(1);
        }
    }
}
</style>
