import { ofetch } from 'ofetch';
import { useAuthStore } from '~/store/auth';

type LaravelRequestConfig = {
    data?: any;
    method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
    params?: any;
};

export async function useLaravelApi(
    path: string,
    config?: LaravelRequestConfig,
    redirectUnauthenticated = true
) {
    const { session } = useUserSession();

    const { public: { laravelApiBaseURL, laravelApiKey } } = useRuntimeConfig();

    const requestHeaders = {
        'x-api-key': laravelApiKey as string,
        Accept: 'application/json',
        Authorization: `Bearer ${session?.value?.accessToken}`,
    };

    return await ofetch(laravelApiBaseURL + path, {
        headers: requestHeaders,
        body: config?.data,
        method: config?.method || 'GET',
        params: {
            email: session.value?.user?.email
        }
    }).catch((error) => {
        if (
            redirectUnauthenticated &&
            error?.response?._data?.message === 'NEEDS_TOKEN_REFRESH'
        ) {
            useNuxtApp().runWithContext(() => {
                useAuthStore().setInitialPageCookie(useRoute().path);

                navigateTo('/auth/login', {
                    external: true
                });
            });
        }
    });
}
