<template>
    <div
        class="error error--hlaml"
    >
        <TemplateHlaml>
            <template #hero>
                <HlamlHero
                    title="Pagina niet gevonden"
                />
            </template>

            <template #default>
                <HlamlContentBlocksText
                    :rich-text="errorMessage"
                />
            </template>
        </TemplateHlaml>
    </div>
</template>

<script setup>
useHead({
    bodyAttrs: {
        'data-hlaml-hero-background': 'blue',
    },
});

const errorMessage = '<p>Deze pagina bestaat niet meer. Ga terug naar de <a href="/hetluktalleenmetlogistiek">homepagina</a> of gebruik het menu om naar een andere pagina te navigeren.</p>';
</script>
