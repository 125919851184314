import DefaultFormField from './components/base/BaseFormField.vue';
import DefaultInput from './components/base/BaseInput.vue';
import DefaultTextarea from './components/base/BaseTextarea.vue';
import DefaultRadio from './components/base/BaseRadio.vue';
import DefaultSelect from './components/base/BaseSelect.vue';
import DefaultCheckbox from './components/base/BaseCheckbox.vue';
import DefaultButton from './components/base/BaseButton.vue';
import DefaultFile from './components/base/BaseFile.vue';

export default class FormiePlugin {
    constructor(_VueApp, config = {}) {
        // Registering base components for Formie
        const baseComponents = {
            // Base default components
            ...{
                formField: DefaultFormField,
                input: DefaultInput,
                textarea: DefaultTextarea,
                radio: DefaultRadio,
                select: DefaultSelect,
                checkbox: DefaultCheckbox,
                button: DefaultButton,
                file: DefaultFile,
            },
            // Custom components from parent project to replace base default components
            ...config.components
        };

        return {
            // Retrieve base component by name
            getComponent(name) {
                return baseComponents[name];
            }
        };
    }
}
