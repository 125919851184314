import FormieForm from './components/FormieForm.vue';
import FormiePlugin from './FormiePlugin';

const Formie = {
    install(app, config = {}) {
        app.provide('formie', new FormiePlugin(app, config));
    }
};

// Exporting base component
export { FormieForm };

export default Formie;
