<template>
    <DnSelect
        class="base-select"
    >
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope"
            />
        </template>
    </DnSelect>
</template>

<script setup>
import DnSelect from '@digitalnatives/select';
</script>

<style src="./BaseSelect.less" lang="less"></style>
