<template>
    <div
        class="columns"
        :class="{
            'columns--reversed': isReversed
        }"
        :data-column-count="Object.keys($slots).length"
    >
        <div
            v-for="name in Object.keys($slots)"
            :key="name"
            class="columns__column"
        >
            <slot
                :name="name"
            />
        </div>
    </div>
</template>

<script setup>
defineProps({
    isReversed: {
        type: Boolean,
        default: false
    }
});
</script>

<style lang="less" src="./BaseColumns.less" />
