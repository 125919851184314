import { useField } from './field';

export const useSubField = () => {
    const getNameSubFields = (field) => {
        return initializeSubfieldsRows(
            [['prefix', 'firstName', 'middleName', 'lastName']],
            field
        );
    };

    const getAddressSubFields = (field) => {
        return initializeSubfieldsRows(
            [
                ['address1'],
                ['address2'],
                ['address3'],
                ['city', 'zip'],
                ['state', 'country'],
            ],
            field
        );
    };

    const initializeSubFieldsData = (subFieldRows) => {
        return Object.values(subFieldRows).reduce((obj, fields) => {
            Object.keys(fields).forEach((key) => {
                obj[key] = useField(fields[key]).getDefaultValue();
            });
            return obj;
        }, {});
    };

    return {
        getNameSubFields,
        getAddressSubFields,
        initializeSubFieldsData,
    };
};

const initializeSubfieldsRows = (list, field) => {
    const enabledSubFieldRows = [];

    list.forEach((row) => {
        // Create an object for each layout row
        const item = row.reduce((obj, subField) => {
            // Check if the subfield is enabled in Formie
            if (field[`${subField}Enabled`]) {
                // Create a field object for each subfield
                obj[subField] = {
                    label: field[`${subField}Label`],
                    handle: [field.handle, field[`${subField}Label`]],
                    required: field[`${subField}Required`],
                    defaultValue: field[`${subField}DefaultValue`],
                    placeholder: field[`${subField}Placeholder`],
                    requiredErrorMessage: field[`${subField}ErrorMessage`],
                    type: subField,
                };
            }
            return obj;
        }, {});

        // Only add the row if it has at least one enabled subfield
        if (Object.keys(item).length) {
            enabledSubFieldRows.push(item);
        }
    });

    return enabledSubFieldRows;
};
