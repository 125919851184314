<template>
    <BaseSection
        v-if="richText"
        section-width="content-sm"
        :title="title"
    >
        <BaseRichText :content="richText" />

        <HlamlHighlightedPage
            v-if="callToAction && callToAction.url"
            :link="callToAction"
            :sub-title="subTitle"
            :image="image.length > 0 ? image[0] : null"
            class="hlaml-content-block__cta"
        />
    </BaseSection>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        default: null
    },
    richText: {
        type: String,
        default: null
    },
    callToAction: {
        type: Object,
        default: null
    },
    subTitle: {
        type: String,
        default: null
    },
    image: {
        type: Array,
        default: null
    }
});
</script>

<style lang="less">
.hlaml-content-block__cta {
    margin-top: 2rem;
}
</style>
